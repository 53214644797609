.container {
  display: flex;
}

.first {
  flex-shrink: 0;
  width: 400px;
}

.prompt-head {
  display: flex;
  justify-content: space-between;
  margin: 0;
}
.time {
  text-align: right;
  font-size: smaller;
  color: #888;  
}

.second {
  margin: 0 0 0 1rem;
  padding: 0.4rem;
  width: 400px;
  border: 1px dotted #888;
  color: #888;
}
.separator {
  margin: 0;
  border-bottom: 1px dotted #ccc;
  text-align: right;
}

.content {
  margin: 0;
}

.control {
  display: flex;
  align-items: stretch;
  width: 100%;
  height: 3rem;
}

.control button {
  margin: 0;
  padding: 0 1rem;
  height: 100%;
}

.control .new-comment {
  flex-grow: 1;
}